import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from './../../actions'
// import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
// import Box from '@material-ui/core/Box'
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
// import Autocomplete from '@material-ui/lab/Autocomplete'
import MuiPhoneNumber from 'material-ui-phone-number'
import CheckIcon from '@material-ui/icons/Check'
import { green } from '@material-ui/core/colors'
import { useForm } from 'react-hook-form'
import Loader from './../Loader'
import moment from 'moment'

import AddToCalendar from 'react-add-to-calendar'
import ReactGA from 'react-ga' // UA-174136504-1
import './AddToCalendar.scss'

// ReactGA.initialize('UA-174136504-1')

function AditionalFormFIelds (props) {
  const formFields = props.formFields
  const register = props.register
  const extraFields = []
  formFields.forEach(function (field) {
    extraFields.push(
      <Grid item xs={12} sm={12} key={'_' + field.name}>
        <TextField
          name={field.name}
          variant='outlined'
          required
          fullWidth
          id={field.name}
          label={field.label}
          inputRef={register}
        />
      </Grid>)
  })
  return extraFields
}

const useStyles = makeStyles((theme) => ({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  webinarTitle: {
    color: '#000',
    'font-weight': 'bold',
    'text-align': 'center',
    '& > span': {
      'padding-top': '5px'
    }
  },
  eventDate: {
    'border-top': '1px solid #ebebeb',
    'border-bottom': '1px solid #ebebeb',
    'margin-top': '15px',
    'margin-bottom': '15px',
    /* padding: 10px; */
    'padding-top': '15px',
    'padding-bottom': '15px',
    margin: '0 auto'
  },
  dateIcon: {
    float: 'left',
    width: '81px',
    height: '85px',
    'background-image': 'url(/assets/datebg.png)',
    'background-repeat': 'no-repeat'
  },
  dateMonth: {
    'padding-top': '9px',
    'text-transform': 'uppercase',
    'text-align': 'center',
    color: '#FFF',
    'font-weight': 'bold',
    'font-size': '12px',
    width: '81px',
    'line-height': '12px',
    'text-shadow': '-1px - 1px 0px rgba(0, 0, 0, 0.25)',
    filter: 'dropshadow(color = rgba(0, 0, 0, 0.25), offx = -1, offy = -1)'
  },
  dateDay: {
    'padding-top': '17px',
    'text-transform': 'uppercase',
    'text-align': 'center',
    color: '#2a2a2a',
    'font-weight': 'bold',
    'font-size': '32px',
    'line-height': '32px',
    width: '81px'
  },
  dateInfo: {
    float: 'left',
    'padding-left': '15px'
  },
  dateHeadline: {
    'font-size': '18px',
    'padding-top': '20px',
    'font-weight': 'bold'
  },
  dateSubHeadline: {
    'font-size': '16px',
    'padding-top': '5px'
  },
  noblockvisible: {
    display: 'none'
  }

}))

const SignUp = (props) => {
  var dataWebinar = {}
  var registry = props.registry
  dataWebinar = props.data.data
  var regdata = props.regdata
  const [enviado, setEnviado] = useState(true)
  const [exito, setExito] = useState(false)
  const [noblockvisible, setOcultar] = useState('noblockvisible')
  const [blockvisible, setMostrar] = useState('')
  const [telefono, setTelefono] = useState('')
  const [errorTelefono, seterrorTelefono] = useState(false)
  const [helpedTelefono, sethelpedTelefono] = useState('')

  const classes = useStyles()

  const handleTelefono = (e) => {
    setTelefono(e)
  }

  const { register, handleSubmit } = useForm()
  const onSubmit = async data => {
    seterrorTelefono(false)
    sethelpedTelefono('')

    if (telefono.length < 7 && dataWebinar.formDefaultSettings.telefono) {
      seterrorTelefono(true)
      sethelpedTelefono('Debe ingresar un número válido')
      return false
    }

    // ReactGA.initialize('UA-174136504-1')
    const Axiosbody = {
      name: data.firstName,
      email: data.email,
      slug: dataWebinar.slug,
      fecha: dataWebinar.eventDate,
      hora: dataWebinar.eventHour,
      eventName: dataWebinar.title,
      empresa: dataWebinar.empresa,
      extraInfo: data,
      mailOption: dataWebinar.sendMailActive
    }
    if (registry(Axiosbody)) {
      setEnviado(!enviado)
      setExito(!exito)
      setOcultar('')
      setMostrar('noblockvisible')
    }
  }

  const fechaEvento = (dataWebinar.eventDate).split('-')

  if (regdata) {
    const then = moment(dataWebinar.eventDate + ' ' + dataWebinar.eventHour, 'DD/MM/YYYY HH:mm').format()
    const eventCalendar = {
      title: dataWebinar.title,
      description: dataWebinar.description,
      location: regdata.url,
      startTime: then,
      endTime: moment(then).add(2, 'hours').format()
    }
    ReactGA.event({
      category: 'Registro',
      action: 'Registro exitoso - ' + dataWebinar.title
    })
    return (
      <>
        <div className={classes.paper}>
          <Grid
            className={classes[noblockvisible]}
            container
            direction='row'
            justify='center'
            alignItems='flex-start'
          >
            <Typography component='h1' variant='h4' className={classes.webinarTitle}>
                ¡Felicidades, ya estás inscrito!
            </Typography>
            <Grid
              container
              spacing={2}
              alignContent='center'
              direction='row'
              justify='center'
              alignItems='flex-start'
            >
              <div className={classes.eventDate}>

                <div className={classes.dateIcon}>
                  <div className={classes.dateMonth}>{dataWebinar.eventMont}</div>
                  <div className={classes.dateDay}>{fechaEvento[0]}</div>
                </div>

                <div className={classes.dateInfo}>
                  <div className={classes.dateHeadline}>{dataWebinar.eventDay}</div>
                  <div className={classes.dateSubHeadline}>{dataWebinar.eventHour} Horas</div>
                </div>
              </div>
            </Grid>
            <Grid
              container
              direction='row'
              justify='center'
              alignItems='flex-start'
            >

              <CheckIcon style={{ color: green[500], fontSize: 20 }} />
              <span>Se ha enviado un correo con la información del evento</span>
            </Grid>

            <Grid
              container
              direction='row'
              justify='center'
              alignItems='flex-start'
              style={{ height: '5rem', margin: '2rem' }}
            >

              <AddToCalendar
                event={eventCalendar}
                buttonLabel='Agregar a mi agenda'
              />

            </Grid>
            <Grid
              container
              direction='row'
              justify='center'
              alignItems='flex-start'

            >
              <Grid  item xs={12} sm={12} justify='center'>

                <span> <strong> Esta es tu URL para conectarte. </strong> </span>
              </Grid>
              <Grid item xs={12} sm={12} justify='center'>
                <Link color='inherit' href={regdata.url}>
                  {regdata.url}
                </Link>
              </Grid>
              <Grid item xs={12} sm={12}>
                {/* <Link color='inherit' href={regdata.url}>
                  {regdata.url}
                </Link> */}
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  href={regdata.url}
                  className={classes.submit}
                >
                  ¡VAMOS ALL&Aacute;!
                </Button>
              </Grid>
            </Grid>

          </Grid>
        </div>
      </>
    )
  } else {
    var campoNombreActivo = true
    var campoApellidoActivo = true
    var campoEmailActivo = true
    var campoTelefonoActivo = true

    if (dataWebinar.formDefaultSettings) {
      campoNombreActivo = dataWebinar.formDefaultSettings.nombre
      campoApellidoActivo = dataWebinar.formDefaultSettings.apellido
      campoEmailActivo = dataWebinar.formDefaultSettings.email
      campoTelefonoActivo = dataWebinar.formDefaultSettings.telefono
    }

    return (
      <>
        <div className={classes[noblockvisible]}>
          <Loader />
        </div>
        <div className={classes.paper}>
          <div className={classes[blockvisible]}>
            <Typography component='h1' variant='h5' className={classes.webinarTitle}>
              {dataWebinar.calltoaction}
            </Typography>

            {/* calendario con la fecha  */}
            <Grid container spacing={2} alignContent='center' alignItems='center'>
              <div className={classes.eventDate}>

                <div className={classes.dateIcon}>
                  <div className={classes.dateMonth}>{dataWebinar.eventMont}</div>
                  <div className={classes.dateDay}>{fechaEvento[0]}</div>
                </div>

                <div className={classes.dateInfo}>
                  <div className={classes.dateHeadline}>{dataWebinar.eventDay}</div>
                  <div className={classes.dateSubHeadline}>{dataWebinar.eventHour} Horas</div>
                </div>
              </div>
            </Grid>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                {(campoNombreActivo) ?
                  <Grid item xs={12} sm={12}>
                    <TextField
                      autoComplete='fname'
                      name='firstName'
                      variant='outlined'
                      required
                      fullWidth
                      id='firstName'
                      label='Nombre'
                      inputRef={register}
                    />
                  </Grid> : <> <input type='hidden' name='firstName' value='-' ref={register} /> </>}
                {(campoApellidoActivo) ?
                  <Grid item xs={12} sm={12}>
                    <TextField
                      name='apellido'
                      variant='outlined'
                      required
                      fullWidth
                      id='apellido'
                      label='Apellido'
                      inputRef={register}
                    />
                  </Grid> : <><input type='hidden' name='apellido' value='-' ref={register} /> </>}

                <AditionalFormFIelds formFields={dataWebinar.formFields} register={register} />
                {(campoEmailActivo) ?
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      required
                      fullWidth
                      id='email'
                      label='Email'
                      name='email'
                      autoComplete='email'
                      inputRef={register}
                    />
                  </Grid> : <> <input type='hidden' name='email' value='-' ref={register} />  </>}
                {(campoTelefonoActivo) ?
                  <Grid item xs={12}>
                    <MuiPhoneNumber
                      variant='outlined'
                      required
                      fullWidth
                      id='phone'
                      label='Teléfono'
                      name='phone'
                      defaultCountry='cl'
                      onChange={handleTelefono}
                      error={errorTelefono}
                      helperText={helpedTelefono}
                    />
                    <input type='hidden' name='telefono' value={telefono} ref={register} />
                  </Grid> : <><input type='hidden' name='telefono' value='0' ref={register} /> </>}
              </Grid>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                ¡REGISTRAR PARA WEBINAR!
              </Button>
            </form>
          </div>

        </div>
      </>
    )
  }// end else
}

const mapStateToProps = state => ({
  data: state.data,
  regdata: state.data.regdata
})
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
