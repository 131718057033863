const LOAD_DATA = 'LOAD_DATA'
const LOAD_DATA_REGISTROS = 'LOAD_DATA_REGISTROS'
const SEND_REGISTRY = 'SEND_REGISTRY'
const LOAD_REGISTRY = 'LOAD_REGISTRY'
const SEND_QUESTION = 'SEND_QUESTION'
const LOAD_PREGUNTAS = 'LOAD_PREGUNTAS'

export {
  LOAD_DATA,
  LOAD_DATA_REGISTROS,
  SEND_REGISTRY,
  LOAD_REGISTRY,
  SEND_QUESTION,
  LOAD_PREGUNTAS
}
