import React from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import Home from './containers/Home'
import GetRegistros from './containers/GetRegistros'
import GetPreguntas from './containers/GetPreguntas'
import Live from './containers/Live'
import Management from './containers/Management'
import { Provider } from 'react-redux'
import store from './store'

const PrivateRoute = ({ children, ...rest }) => {
  // console.log('comprobando token...')
  // console.log('...rest ---->', { ...rest })
  return (

    <Route
      {...rest}
      render={({ location }) =>
        window.localStorage.getItem('token') && window.localStorage.getItem('token').length > 0 ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/dologin',
              state: { from: location }
            }}
          />)}
    />

  )
}

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <PrivateRoute exact path='/management'>
          <Management />
        </PrivateRoute>
        <Route exact path='/registros/:eventPath' component={GetRegistros} />
        <Route exact path='/preguntas/:eventPath' component={GetPreguntas} />
        <Route exact path='/:eventPath' component={Home} />
        <Route exact path='/live/:eventPath/:userId' component={Live} />
        {/* <Route exact path='/unauthorized' component={Unauthorized} />
        <Route exact path='/logout' component={AdfsLogout} /> */}
      </Router>
    </Provider>
  )
}

export default App
