import React from 'react'
import ReactExport from 'react-export-excel'
import { connect } from 'react-redux'
import { loadDataRegistros, loadDataWebinar } from '../actions/'
import Loader from '../components/Loader'
import { Container } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import './HomeRegistros.scss'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class GetRegistros extends React.Component {
  constructor (props) {
    super(props)
    // props.loadData();
    // props.loadDataWebinar(props.location.pathname.substr(1))
    this.eventPath = props.match.params.eventPath
    props.loadDataRegistros(this.eventPath)
    props.loadDataWebinar(this.eventPath)
    // console.log(props.location.pathname.substr(1))
  }

  render () {
    if (this.props.data_registros && this.props.data_evento) {
      // console.log(this.props.data_registros)
      const datosExcel = this.props.data_registros
      const datosEvento = this.props.data_evento
      const extraFields = []
      datosExcel.forEach((field) => {
        field.extraInfo.link = field.link
        extraFields.push(field.extraInfo)
      })

      // console.log('------>', extraFields)
      const celdas = []
      const objCampoNombre = {
        firstName: 'Nombre',
        telefono: 'Telefono',
        especialidad: 'Especialidad',
        email: 'E-mail',
        apellido: 'Apellido'
      }
      Object.keys(extraFields[0]).forEach(function (key) {
        celdas.push(
          <ExcelColumn key={key} label={objCampoNombre[key]} value={key} />
        )
      })

      // const fecha = Date.now()
      // console.log(fecha)

      return (
        <Container maxWidth='sm'>
          <Grid
            container
            spacing={0}
            direction='row'
            justify='center'
            alignItems='center'
            alignContent='center'
            wrap='nowrap'
            pt={30}

          >

            <Box component='span' m={20}>
              <Typography variant='h3' component='h1'>
                {datosEvento.title}
              </Typography>
            </Box>
            <Box component='span' m={20}>
              <ExcelFile filename={datosEvento.slug + '-' + Date.now()} element={<Button size='large' variant='contained' color='primary'>Descargar Registros</Button>}>
                <ExcelSheet data={extraFields} name={'Registros ' + this.eventPath}>
                  {celdas}
                </ExcelSheet>
              </ExcelFile>
            </Box>
          </Grid>

        </Container>

      )
    } else {
      return (

        <Loader />

      )
    }
  }
}

const mapStateToProps = state => ({
  data_registros: state.data.data_registros,
  data_evento: state.data.data
})

const mapDispatchToProps = dispatch => ({
  loadDataRegistros (eventPath) {
    dispatch(loadDataRegistros(eventPath))
  },
  loadDataWebinar (eventPath) {
    dispatch(loadDataWebinar(eventPath))
  }

})

export default connect(mapStateToProps, mapDispatchToProps)(GetRegistros)
