import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ButtonBase from '@material-ui/core/ButtonBase'
import Divider from '@material-ui/core/Divider'

function Speakers (props) {
  const speakers = props.speakers
  // console.log(speakers)
  const extraFields = []
  speakers.forEach(function (field) {
    //  console.log(field)
    extraFields.push(
      <Typography variant='body2' color='textSecondary' key={field.speaker}>
        {field.speaker}
      </Typography>)
  })
  return extraFields
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: '100%',
    backgroundColor: '#fff'
  },
  image: {
    width: '100%',
    height: '150px'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  }
}))

export default function ComplexGrid (props) {
  const classes = useStyles()
  const { ruta, description, relatores } = props

  // console.log(relatores)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={20}>
        <Grid container spacing={1}>
          <Grid item>
            <ButtonBase className={classes.image}>
              <img className={classes.img} alt='evento' src={ruta} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction='column' spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant='subtitle1'>
                  {description}
                </Typography>
                <Divider />
                <Speakers speakers={relatores} />
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}
