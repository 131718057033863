import React, { Component } from 'react'

import Loader from '../components/Loader'
import Infowebinar from '../components/Infowebinar/Infowebinar'
// import Makequestion from '../components/Makequestion/Makequestion'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import AficheHeader from '../components/AficheHeader/AficheHeader'
import Paper from '@material-ui/core/Paper'

import { connect } from 'react-redux'
import { loadDataWebinar, loadregistry } from '../actions'

import { Helmet } from 'react-helmet'
import Link from '@material-ui/core/Link'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import moment from 'moment'

import ReactGA from 'react-ga' // UA-174136504-1

import './Live.scss'





function Copyright() {
  return (
    <Typography variant='body2'>
      {'Copyright © '}
      <Link color='inherit' href='https://endirecto.cl/'>
        Endirecto.cl
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
const styles = {
  root: {
    color: '#000',
    'background-color': '#009cde',
    flexGrow: 1
  },
  paper: {
    height: 100,
    width: 100,
    'text-align': 'center',
    padding: 10
  },
  signup: {
    background: '#fff',
  },
  title: {
    color: '#fff',
    // 'font-size': '3.5em',
    'font-weight': 300,
    'font-family': 'Helvetica Neue , Helvetica, Helvetica, Arial, sans-serif',
    margin: '0 auto'
  },
  subtitle: {
    color: '#fff',
    // 'font-size': '2.75em',
    'font-weight': 100,
    'font-family': 'Verdana',
    'text-align': 'left',
    'font-size': '12px',
    'min-height': '30px',
    display: 'inline-flex',
    'align-items': 'center'
  },
  subtitle2: {
    color: '#000',
    // 'font-size': '2.75em',
    'font-weight': 100,
    'font-family': 'Verdana',
    'text-align': 'center'
  },
  cajaCountDown: {
    padding: 20
  },
  footer: {
    padding: 20,
    marginTop: 'auto',
    'background-color': '#029cdd',
    color: '#fff',
    'text-align': 'center'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  titleCard: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  iframeLive: {
    background: '#fff',
    width: '100%',
    height: '600px',
    display: 'block',
    margin: '0 auto'
  },
  contenido: {
    // padding: '20px !important'
    // backgroundColor: '#cdcdcd'
  }
}

class Replay extends Component {
  constructor (props) {
    super(props)
    // props.loadData();
    // props.loadDataWebinar(props.location.pathname.substr(1))
    this.eventPath = props.eventPath
    this.userId = props.userId

    // var loadregistry = props.loadregistry(props.user)
    props.loadregistry(this.userId)
    // console.log(props.location.pathname.substr(1))

    this.state = {
      dias: '',
      horas: '',
      minutos: '',
      segundos: ''
    }
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => {
        // console.log(this.props.infoWebinar)
        if (this.props.infoWebinar) {
          const webinar = this.props.infoWebinar
          var now = moment(Date.now())
          var then = moment(webinar.eventDate + ' ' + webinar.eventHour, 'DD/MM/YYYY HH:mm').add(14, 'd')
          var ms = moment(then).diff(now)
          if (ms > 0) {
            var d = moment.duration(ms)
            this.setState({
              dias: d.days(),
              horas: d.hours(),
              minutos: d.minutes(),
              segundos: d.seconds()
            })
          } else {
            this.setState({
              dias: 0,
              horas: 0,
              minutos: 0,
              segundos: 0
            })
            clearInterval(this.timerID)
          }
        }
      },
      1000
    )
  }

  render () {
    // console.log('------------->', this.props.infoRegistro)
    const { classes } = this.props
    if (this.props.infoRegistro && this.props.infoWebinar) {
      const webinar = this.props.infoWebinar
      if (this.props.infoRegistro.length === 0) {
        window.alert('La URL es incorrecta. Revise su correo o regístrese')
        window.location.replace('/' + webinar.slug)
        return (
          <></>
        )
      }

      ReactGA.initialize('UA-174136504-1', {
        debug: false,
        titleCase: false,
        gaOptions: {
          userId: this.userId
        }
      })
      ReactGA.pageview('live/replay/' + this.eventPath + '/')
      ReactGA.ga('send', 'pageview', { sessionControl: 'start' })

      return (
        <>
          <Helmet>
            <meta charSet='utf-8' />
            <title>{webinar.title} - EnDirecto.cl</title>
            <meta name='description' content={webinar.description} />
            <meta property='og:title' content={webinar.title} />
            <meta property='og:image' content={'https://cdn.endirecto.cl/imagenes/eventos/' + webinar.slug + '/afiche.jpg'} />
            <meta property='og:url' content={'https://' + webinar.title + '.endirecto.cl/your-page.html' + webinar.slug} />
            <meta property='og:type' content='website' />
            <meta property='og:title' content={webinar.title + '- EnDirecto.cl'} />
            <meta property='og:description' content={webinar.description} />
          </Helmet>
          <CssBaseline />
          <Container component='div' maxWidth='md' className={classes.root} p={20}>
            <Grid item xs={12} md={12} className={classes.aficheHeader}>
              {(this.props.infoWebinar) ? <AficheHeader ruta={webinar.slug} /> : <> </>}
            </Grid>
            
            {/* <Grid container>
              <Box mx='auto' p={5}>
                <Typography variant='h1' component='h1' mx='auto' className={classes.title}>
                  {webinar.title}
                </Typography>

              </Box>

            </Grid> */}
            <Grid container spacing={4} className={classes.cajaCountDown}>
              <Typography variant='h5' component='h5' mx='auto' align='center' className={classes.title}>
                Disponible hasta
              </Typography>
              <Grid item xs={12}>
                <Grid container justify='center' spacing={2}>

                  <Grid item>
                    <Paper className={classes.paper}>
                      <span className='countdown_amount'>{this.state.dias}</span>
                      <br />Dias
                    </Paper>
                  </Grid>

                  <Grid item>
                    <Paper className={classes.paper}>
                      <span className='countdown_amount'>{this.state.horas}</span>
                      <br />Horas
                    </Paper>
                  </Grid>

                  <Grid item>
                    <Paper className={classes.paper}>
                      <span className='countdown_amount'>{this.state.minutos}</span>
                      <br />Minutos
                    </Paper>
                  </Grid>

                  <Grid item>
                    <Paper className={classes.paper}>
                      <span className='countdown_amount'>{this.state.segundos}</span>
                      <br />Segundos
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
            >
              <Infowebinar
                ruta={'https://cdn.endirecto.cl/imagenes/eventos/' + webinar.slug + '/afiche.jpg'}
                description={webinar.description}
                relatores={webinar.speakers}
              />
            </Grid>
            <Grid
              container
              spacing={1}
              direction='row'
              justify='center'
              alignItems='center'
              alignContent='center'
              wrap='nowrap'

            >
              
              <Box mx='auto' p={1}>
                <Typography variant='subtitle1' component='span' className={classes.subtitle}>
                  <VolumeUpIcon />Active sus parlantes o audífonos y presionar play
                </Typography>
              </Box>
            </Grid>
            <Grid container spacing={2} className={classes.contenido}>
              <Grid item xs={12} md={12} className={classes.signup}>

                <iframe title='webinar' src={webinar.iframe} className={classes.iframeLive} frameBorder='0' allow='autoplay; fullscreen' allowFullScreen />
                {/* <Makequestion user={this.userId} /> */}
              </Grid>
              
            </Grid>
            {/* <Grid
              xs={12}
              md={4}
              className={classes.signup}
              container
            >
            </Grid> */}
            {/* </Container>
          <Container maxWidth="md"> */}
            <footer className={classes.footer}>
              <Container maxWidth='sm'>
                <Copyright />
              </Container>
            </footer>
          </Container>
        </>
      )
    } else {
      return (
        <Loader />
      )
    }
  }
}

const mapStateToProps = state => ({
  infoWebinar: state.data.data,
  infoRegistro: state.data.data_registro
})

const mapDispatchToProps = dispatch => ({
  loadDataWebinar (eventPath) {
    dispatch(loadDataWebinar(eventPath))
  },
  loadregistry (userId) {
    dispatch(loadregistry(userId))
  }

})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Replay))
// export default withStyles(styles)(Home)
