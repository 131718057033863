import React, { Component } from 'react'

class Management extends Component {
  render () {
    return (
      <div>
        admiiiin
      </div>
    )
  }
}

export default Management
