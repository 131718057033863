import React, { Component } from 'react'
import SignUp from '../components/Forms/SignUp'
import Afiche from '../components/Afiche/Afiche'
import AficheHeader from '../components/AficheHeader/AficheHeader'
import Loader from '../components/Loader'
// import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
// import Typography from '@material-ui/core/Typography'
// import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Helmet } from 'react-helmet'
// import Link from '@material-ui/core/Link'
import { Contenedor, ContenedorAfiche, SeccionTitulo, SeccionCopyright } from './Styles'

import { connect } from 'react-redux'
import { loadDataWebinar } from '../actions/'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

import ReactGA from 'react-ga' // UA-174136504-1

import './Home.scss'

function Copyright () {
  return (
    <Typography variant='body2' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://endirecto.cl/'>
        EnDirecto.cl
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
class Home extends Component {
  constructor (props) {
    super(props)
    this.eventPath = props.match.params.eventPath
    props.loadDataWebinar(this.eventPath)
  }

  componentDidMount () {
    ReactGA.initialize('UA-174136504-1')
    ReactGA.pageview('/' + this.eventPath + '/inscripcion')
  }

  render () {
    if (this.props.infoWebinar) {
      const webinar = this.props.infoWebinar
      document.body.style.backgroundColor = (webinar.body.bodybackground) ? webinar.body.bodybackground : '#000'
      return (
        <>
          <Helmet>
            <meta charSet='utf-8' />
            <title>{webinar.title} - EnDirecto.cl</title>
            <meta name='description' content={webinar.description} />
            <meta property='og:title' content={webinar.title} />
            <meta property='og:image' content={'https://cdn.endirecto.cl/imagenes/eventos/' + webinar.slug + '/afiche.jpg'} />
            <meta property='og:url' content={'https://' + webinar.title + '.endirecto.cl/' + webinar.slug} />
            <meta property='og:type' content='website' />
            <meta property='og:title' content={webinar.title + '- EnDirecto.cl'} />
            <meta property='og:description' content={webinar.description} />
          </Helmet>
          <CssBaseline />
          <Contenedor rootcolor={webinar.body.rootcolor} rootbackground={webinar.body.rootbackground}>
            <ContenedorAfiche xs={12} md={12} classe='aficheHeader' aficheheaderbackground={webinar.body.aficheheaderbackground}>
              <AficheHeader ruta={webinar.slug} />
            </ContenedorAfiche> {/* classes.tittle */}
            <Grid container>
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  spacing={1}
                  direction='row'
                  justify='center'
                  alignItems='center'
                  alignContent='center'
                  wrap='nowrap'>

                  <Box mx='auto' p={5}>
                    <SeccionTitulo variant='h1' component='h1' classe='title' titlecolor={webinar.body.titlecolor}>
                      {webinar.title}
                    </SeccionTitulo>

                    <SeccionTitulo variant='h5' component='h5' classe='subtitle' subtitlecolor={webinar.body.subtitlecolor}>
                      {webinar.description}
                    </SeccionTitulo>
                  </Box>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} md={8} className={classes.afiche}> */}
              <ContenedorAfiche xs={12} md={8} classe='afiche' afichebackground={webinar.body.afichebackground}>
                {/* {(!this.props.infoRegistro) ? <Afiche rutaAfiche={webinar.slug} /> : <> </>} */}
                <Afiche rutaAfiche={webinar.slug} />
              </ContenedorAfiche>

              <ContenedorAfiche xs={12} md={4} classe='signup' signupbackground={webinar.body.signupbackground} rel='jaime'>
                <Box mx='auto' px={5}>
                  <SignUp />
                </Box>
              </ContenedorAfiche>

              {/* <Grid item xs={8} md={8} className={classes.afiche}>
                {(this.props.infoRegistro) ? <Afiche rutaAfiche={webinar.slug} /> : <> </>}
              </Grid> */}

            </Grid>
            <SeccionCopyright mt={5} p={10} copyrightcolor={webinar.body.copyrightcolor}>
              <Copyright />
            </SeccionCopyright>
          </Contenedor>
        </>
      )
    } else {
      return (
        <Loader />
      )
    }
  }
}

const mapStateToProps = state => ({
  infoWebinar: state.data.data,
  infoRegistro: state.data.regdata
})

const mapDispatchToProps = dispatch => ({
  loadDataWebinar (eventPath) {
    dispatch(loadDataWebinar(eventPath))
  }

})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
