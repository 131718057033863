import { combineReducers } from 'redux'
import {
  LOAD_DATA, LOAD_DATA_REGISTROS, SEND_REGISTRY, LOAD_REGISTRY, LOAD_PREGUNTAS
} from '../actions/types'

const INITIAL_STATE = {
  data: false,
  data_registros: false,
  regdata: false,
  data_registro: false,
  data_preguntas: false
}
const data = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_DATA:
      return {
        ...state,
        data: action.payload
      }
    case LOAD_PREGUNTAS:
      return {
        ...state,
        data_preguntas: action.payload
      }
    case LOAD_DATA_REGISTROS:
      return {
        ...state,
        data_registros: action.payload
      }
    case LOAD_REGISTRY:
      return {
        ...state,
        data_registro: action.payload
      }
    case SEND_REGISTRY:
      return {
        ...state,
        regdata: action.payload
      }
    default:
      return state
  }
}

export default combineReducers({ data })
