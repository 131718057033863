import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ButtonBase from '@material-ui/core/ButtonBase'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: '100%',
    backgroundColor: '#fff'
  },
  image: {
    width: '100%',
    height: '150px'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  }
}))

function goToPoll () {
  console.log('marca encuesta')
}

export default function Poll (props) {
  const classes = useStyles()
  if (!props) {
    return (<></>)
  }
  const { active, description, url } = props.data

  if (!active) {
    console.log('Poll not active')
    return (<></>)
  }
  const ruta = 'https://cdn.endirecto.cl/imagenes/eventos/' + props.eventSlug + '/qr.png'
  // console.log(props)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={20}>
        <Grid container spacing={1}>
          <Grid item>
            <ButtonBase className={classes.image}>
              <img className={classes.img} alt='evento' src={ruta} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction='column' spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant='subtitle1'>
                  Encuesta
                </Typography>
                <Divider />
                {/* <Speakers speakers={relatores} /> */}
                {description}
                <br />
                <span style={{ marginTop: '10px', display: 'block' }}>
                  <Button variant='contained' color='primary' href={url} target='_blank' onClick={goToPoll}>
                    Ir a la encuesta
                  </Button>
                </span>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}
