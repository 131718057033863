import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

export var stylesNew = makeStyles({
  root: {
    color: (props) => props.rootcolor ? props.rootcolor : '#0f0',
    'background-color': (props) => props.rootbackground ? props.rootbackground : '#000'
  },
  afiche: {
    background: (props) => props.afichebackground ? props.afichebackground : '#0f0'
  },
  aficheHeader: {
    background: (props) => props.aficheheaderbackground ? props.aficheheaderbackground : '#0f0'
  },
  signup: {
    background: (props) => props.signupbackground ? props.signupbackground : '#0f0'
  },
  title: {
    color: (props) => props.titlecolor ? props.titlecolor : '#0f0',
    'font-size': '2.5em',
    'font-weight': 300,
    'font-family': 'Helvetica Neue , Helvetica, Helvetica, Arial, sans-serif'
  },
  subtitle: {
    color: (props) => props.subtitlecolor ? props.subtitlecolor : '#0f0',
    'font-size': '1em',
    'font-weight': 100,
    'font-family': 'Verdana',
    'text-align': 'center'
  },
  copyright: {
    color: (props) => props.copyrightcolor
  }
})

export function Contenedor (props) {
  const classes = stylesNew(props)
  return <Container component='div' maxWidth='lg' className={classes.root}> {props.children}</Container>
}

export function ContenedorAfiche (props) {
  const classes = stylesNew(props)
  return <Grid item xs={props.xs} md={props.md} className={classes[props.classe]}> {props.children} </Grid>
}

export function SeccionTitulo (props) {
  const classes = stylesNew(props)
  return <Typography variant={props.variant} component={props.component} mx='auto' className={classes[props.classe]}> {props.children} </Typography>
}
export function SeccionCopyright (props) {
  const classes = stylesNew(props)
  return <Box mt={5} p={10} className={classes.copyright}> {props.children} </Box>
}
export function Copyright () {
  return (
    <Typography variant='body2'>
      {'Copyright © '}
      <Link color='inherit' href='https://endirecto.cl/'>
        Endirecto.cl
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
