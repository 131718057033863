import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from './../../actions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import SendIcon from '@material-ui/icons/Send'

function Alert (props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({

  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  webinarTitle: {
    'font-weight': 'bold',
    'text-align': 'center',
    '& > span': {
      'padding-top': '5px'
    }
  },
  button: {
    width: '100%',
    height: '100%'
  },
  inputPregunta: {
    paddingRight: '0 !important'
  },
  botonPregunta: {
    paddingLeft: '0 !important'
  }
}))

const Makequestions = (props) => {

  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }

  var dataWebinar = {}
  // var registry = props.registry
  var registryQuestion = props.registryQuestion
  dataWebinar = props.data.data
  // var regdata = props.regdata
  var loadregistryinfo = props.loadregistryinfo[0]

  // console.log('---->>>>>>>', loadregistryinfo)
  const [enviado, setEnviado] = useState(true)
  const [exito, setExito] = useState(false)
  const classes = useStyles()

  const { register, handleSubmit, setValue } = useForm()

  const onSubmit = async data => {
    const Axiosbody = {
      nombre: data.nombre,
      email: data.email,
      eventSlug: dataWebinar.slug,
      question: data.question
    }
    if (registryQuestion(Axiosbody)) {
      setValue('question', '')
      setEnviado(!enviado)
      setExito(!exito)
      handleClick()
    }
  }

  if (loadregistryinfo) {
    return (
      <>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity='success'>
            ¡Pregunta enviada!
          </Alert>
        </Snackbar>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <input type='hidden' required name='nombre' ref={register} value={loadregistryinfo.name} />
          <input type='hidden' required name='eventSlug' ref={register} value={loadregistryinfo.slug}/>
          <input type='hidden' required name='email' ref={register} value={loadregistryinfo.email}/>
          <Grid container spacing={1}>
            <Grid item xs={10} sm={10} className={classes.inputPregunta}>
              <TextField
                name='question'
                variant='outlined'
                required
                fullWidth
                id='question'
                label={`¿Tienes alguna pregunta ${loadregistryinfo.name}?`}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={2} sm={2} className={classes.botonPregunta}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                className={classes.button}
                endIcon={<SendIcon />}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    )
  } else {
    return <></>
  }
}

const mapStateToProps = state => ({
  data: state.data,
  regdata: state.data.regdata,
  loadregistryinfo: state.data.data_registro

})
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Makequestions)
