import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
// import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
// import ButtonBase from '@material-ui/core/ButtonBase';

const useStyles = makeStyles((theme) => ({
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    padding: '10px'
  }
}))

export default function AficheHeader (props) {
  const classes = useStyles()
  const { ruta } = props
  return (
    <Grid container className={classes.img}>
      <img className={classes.img} alt='afiche' src={'https://cdn.endirecto.cl/imagenes/eventos/' + ruta + '/header.png'} />
    </Grid>
  )
}
