import React, { Component } from 'react'
// import SignUp from '../components/Forms/SignUp'
import Afiche from '../components/Afiche/Afiche'
import AficheHeader from '../components/AficheHeader/AficheHeader'
import Loader from '../components/Loader'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Button } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'

import { connect } from 'react-redux'
import { loadDataWebinar } from '../actions'

import { Helmet } from 'react-helmet'
import moment from 'moment'
import Link from '@material-ui/core/Link'
import ReactGA from 'react-ga' // UA-174136504-1
import { Contenedor, ContenedorAfiche } from './Styles'

import './Live.scss'

const styles = {
  root: {
    color: '#000',
    'background-color': '#009cde',
    flexGrow: 1
  },
  paper: {
    height: 100,
    width: 100,
    'text-align': 'center',
    padding: 10
  },
  afiche: {
    background: '#000'
  },
  signup: {
    background: '#fff',
    'margin-top': 30
  },
  title: {
    color: '#000',
    'font-size': '3.5em',
    'font-weight': 300,
    'font-family': 'Helvetica Neue , Helvetica, Helvetica, Arial, sans-serif'
  },
  subtitle: {
    color: '#000',
    // 'font-size': '2.75em',
    'font-weight': 100,
    'font-family': 'Verdana',
    'text-align': 'center',
    margin: 20
  },
  subtitle2: {
    color: '#000',
    'font-size': '1em',
    'font-weight': 100,
    'font-family': 'Verdana',
    'text-align': 'center'
  },
  cajaCountDown: {
    padding: 20
  },
  footer: {
    padding: 20,
    marginTop: 'auto',
    'background-color': '#fff',
    color: '#000',
    'text-align': 'center'
  }
}

function Copyright () {
  return (
    <Typography variant='body2' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://endirecto.cl/'>
        EnDirecto.cl
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

// var stylesNew = makeStyles({
//   root: {
//     color: (props) => props.rootcolor ? props.rootcolor : '#0f0',
//     'background-color': (props) => props.rootbackground ? props.rootbackground : '#000'
//   },
//   afiche: {
//     background: (props) => props.afichebackground ? props.afichebackground : '#0f0'
//   },
//   aficheHeader: {
//     background: (props) => props.aficheheaderbackground ? props.aficheheaderbackground : '#0f0'
//   },
//   signup: {
//     background: (props) => props.signupbackground ? props.signupbackground : '#0f0'
//   },
//   title: {
//     color: (props) => props.titlecolor ? props.titlecolor : '#0f0',
//     'font-size': '2.5em',
//     'font-weight': 300,
//     'font-family': 'Helvetica Neue , Helvetica, Helvetica, Arial, sans-serif'
//   },
//   subtitle: {
//     color: (props) => props.subtitlecolor ? props.subtitlecolor : '#0f0',
//     'font-size': '1em',
//     'font-weight': 100,
//     'font-family': 'Verdana',
//     'text-align': 'center'
//   },
//   copyright: {
//     color: (props) => props.copyrightcolor
//   }
// })

// function Contenedor(props) {
//   const classes = stylesNew(props)
//   return <Container component='div' maxWidth={props.maxWidth ? props.maxWidth : 'lg'} className={classes.root}> {props.children}</Container>
// }

// function ContenedorAfiche(props) {
//   const classes = stylesNew(props)
//   return <Grid item xs={props.xs} md={props.md} className={classes[props.classe]}> {props.children} </Grid>
// }

// function SeccionTitulo(props) {
//   const classes = stylesNew(props)
//   return <Typography variant={props.variant} component={props.component} mx='auto' className={classes[props.classe]}> {props.children} </Typography>
// }
// function SeccionCopyright(props) {
//   const classes = stylesNew(props)
//   return <Box mt={5} p={10} className={classes.copyright}> {props.children} </Box>
// }

class Preview extends Component {
  constructor (props) {
    super(props)
    // props.loadData();
    // props.loadDataWebinar(props.location.pathname.substr(1))
    this.eventPath = props.eventPath
    this.userId = props.userId
    props.loadDataWebinar(this.eventPath)
    // console.log(props.location.pathname.substr(1))

    this.state = {
      dias: '',
      horas: '',
      minutos: '',
      segundos: ''
    }
  }

  componentDidMount () {
    
    ReactGA.pageview('live/preview/' + this.eventPath + '/')

    this.timerID = setInterval(
      () => {
        // console.log(this.props.infoWebinar)
        if (this.props.infoWebinar) {
          const webinar = this.props.infoWebinar
          var now = moment(Date.now())
          var then = moment(webinar.eventDate + ' ' + webinar.eventHour, 'DD/MM/YYYY HH:mm')
          var ms = moment(then).diff(now)
          if (ms > 0) {
            var d = moment.duration(ms)
            this.setState({
              dias: d.days(),
              horas: d.hours(),
              minutos: d.minutes(),
              segundos: d.seconds()
            })
          } else {
            this.setState({
              dias: 0,
              horas: 0,
              minutos: 0,
              segundos: 0
            })
            clearInterval(this.timerID)
          }
        }
      },
      1000
    )
  }

  render () {
    const { classes } = this.props
    if (this.props.infoWebinar) {
      const webinar = this.props.infoWebinar

      

      // validar aca el estado de registros del evento
      return (
        <>
          <Helmet>
            <meta charSet='utf-8' />
            <title>{webinar.title} - EnDirecto.cl</title>
            <meta name='description' content={webinar.description} />
            <meta property='og:title' content={webinar.title} />
            <meta property='og:image' content={'https://cdn.endirecto.cl/imagenes/eventos/' + webinar.slug + '/afiche.jpg'} />
            <meta property='og:url' content={'https://' + webinar.title + '.endirecto.cl/your-page.html' + webinar.slug} />
            <meta property='og:type' content='website' />
            <meta property='og:title' content={webinar.title + '- EnDirecto.cl'} />
            <meta property='og:description' content={webinar.description} />
          </Helmet>
          <CssBaseline />
          {/* <Container component='div' maxWidth='md' className={classes.root}> */}
          <Contenedor maxWidth='md' rootcolor={webinar.body.rootcolor} rootbackground={webinar.body.rootbackground}>
            <Grid item xs={12} md={12} className={classes.aficheHeader}>
              {(!this.props.infoRegistro) ? <AficheHeader ruta={webinar.slug} /> : <> </>}
            </Grid>
            <Grid container spacing={4} className={classes.cajaCountDown}>
              <Grid item xs={12}>
                <Grid container justify='center' spacing={2}>

                  <Grid item>
                    <Paper className={classes.paper}>
                      <span className='countdown_amount'>{this.state.dias}</span>
                      <br />Dias
                    </Paper>
                  </Grid>

                  <Grid item>
                    <Paper className={classes.paper}>
                      <span className='countdown_amount'>{this.state.horas}</span>
                      <br />Horas
                    </Paper>
                  </Grid>

                  <Grid item>
                    <Paper className={classes.paper}>
                      <span className='countdown_amount'>{this.state.minutos}</span>
                      <br />Minutos
                    </Paper>
                  </Grid>

                  <Grid item>
                    <Paper className={classes.paper}>
                      <span className='countdown_amount'>{this.state.segundos}</span>
                      <br />Segundos
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              
              {/* <Grid item xs={12} md={12} className={classes.afiche}>
                <Afiche rutaAfiche={webinar.slug} />
              </Grid> */}

              <ContenedorAfiche xs={12} md={12} classe='afiche' afichebackground={webinar.body.afichebackground}>
                {/* {(!this.props.infoRegistro) ? <Afiche rutaAfiche={webinar.slug} /> : <> </>} */}
                <Afiche rutaAfiche={webinar.slug} />
              </ContenedorAfiche>
              {/* <Box mx='auto' p={5}>
                <Typography variant='h1' component='h1' mx='auto' className={classes.title}>
                  {webinar.title}
                </Typography>

                <Typography variant='h5' component='h5' mx='auto' className={classes.subtitle}>
                  {webinar.description}
                </Typography>
              </Box> */}
              <Grid container item xs={12} md={12} className={classes.signup} justify='center'>
                <Box mx='auto' p={12}>
                  <Typography variant='h1' align='center' component='h1' mx='auto' className={classes.title}>
                    {webinar.title}
                  </Typography>
                  <Typography variant='h5' component='h5' mx='auto' p='10' className={classes.subtitle}>
                    {webinar.description}
                  </Typography>
                  {/* <SignUp /> */}
                  <Typography p={10} align='center' variant='h5' component='h5' mx='auto' className={classes.subtitle2}>
                    ¿Aún no se ha inscrito?
                  </Typography>

                  <br />
                  <Grid
                    container
                    spacing={1}
                    direction='row'
                    justify='center'
                    alignItems='center'
                    alignContent='center'
                    wrap='nowrap'
                  >
                    <Paper>
                      <Button variant='contained' color='primary' size='medium' href={'/' + webinar.slug}>
                        ¡Quiero registrarme!
                      </Button>
                    </Paper>
                  </Grid>
                </Box>
              </Grid>

            </Grid>
            <footer className={classes.footer}>
              <Container maxWidth='sm'>
                {/* <Typography variant='body1'>My sticky footer can be found here.</Typography> */}
                <Copyright />
              </Container>
            </footer>
          </Contenedor>

        </>
      )
    } else {
      return (
        <Loader />
      )
    }
  }
}

const mapStateToProps = state => ({
  infoWebinar: state.data.data
})

const mapDispatchToProps = dispatch => ({
  loadDataWebinar (eventPath) {
    dispatch(loadDataWebinar(eventPath))
  }

})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Preview))
// export default withStyles(styles)(Home)
