import React, { Component } from 'react'
import Loader from '../components/Loader'
import { connect } from 'react-redux'
import { loadDataWebinar } from '../actions/'
import './Live.scss'
import Preview from './Preview'
import Envivo from './Envivo'
import Replay from './Replay'
import ReactGA from 'react-ga'

class Live extends Component {
  constructor (props) {
    super(props)
    this.eventPath = props.match.params.eventPath
    this.userId = props.match.params.userId
    props.loadDataWebinar(this.eventPath)
  }

  componentDidMount () {
    ReactGA.initialize('UA-174136504-1', {
      debug: false,
      titleCase: false,
      gaOptions: {
        userId: this.userId
      }
    })
  }

  render () {
    if (this.props.infoWebinar) {
      const statusWebinar = this.props.infoWebinar.status
      document.body.style.backgroundColor = (this.props.infoWebinar.body.bodybackground) ? this.props.infoWebinar.body.bodybackground : '#000'
      switch (statusWebinar) {
        case 'live':
          return (
            <Envivo eventPath={this.eventPath} userId={this.userId} />
          )
        case 'replay':
          return (
            <Replay eventPath={this.eventPath} userId={this.userId} />
          )
        case 'registration':
        default:
          return (
            <Preview eventPath={this.eventPath} userId={this.userId} />
          )
      }
    } else {
      return (
        <Loader />
      )
    }
  }
}

const mapStateToProps = state => ({
  infoWebinar: state.data.data
})

const mapDispatchToProps = dispatch => ({
  loadDataWebinar (eventPath) {
    dispatch(loadDataWebinar(eventPath))
  }

})

export default connect(mapStateToProps, mapDispatchToProps)(Live)
