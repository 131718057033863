import axios from 'axios'
import {
  LOAD_DATA,
  LOAD_DATA_REGISTROS,
  SEND_REGISTRY,
  LOAD_REGISTRY,
  SEND_QUESTION,
  LOAD_PREGUNTAS
} from './types'

// const token = window.localStorage.getItem('token')
// const paisSeleccionado = window.localStorage.getItem('pais')
// const userTimeZone = window.localStorage.getItem('userTimeZone')
// var offset = new Date().getTimezoneOffset()
const url = 'https://tan7shg9kg.execute-api.us-east-1.amazonaws.com/prod'

const loadDataWebinar = (slug = '') => async (dispatch) => {
  //  trae todas la configuración del webinar

  try {
    const { data } = await axios.get(url + `/webinarslug/${slug}`, { headers: { 'Content-Type': 'application/json' } })
    if (data.error) {
      console.error(data.error)
    }
    dispatch({
      type: LOAD_DATA,
      payload: data
    })
  } catch (err) {
    console.error(err)
  }

  setInterval(async () => {
    try {
      const { data } = await axios.get(url + `/webinarslug/${slug}`, { headers: { 'Content-Type': 'application/json' } })
      if (data.error) {
        console.error(data.error)
      }
      dispatch({
        type: LOAD_DATA,
        payload: data
      })
    } catch (err) {
      console.error(err)
    }
  }, 20000)
}
const loadDataRegistros = (slug = '') => async (dispatch) => {
  //  trae todas la configuración del webinar

  try {
    const { data } = await axios.post(`https://d70255btwa.execute-api.us-east-1.amazonaws.com/prod/registros/${slug}`, { headers: { 'Content-Type': 'application/json' } })
    if (data.error) {
      console.error(data.error)
    }
    dispatch({
      type: LOAD_DATA_REGISTROS,
      payload: data
    })
  } catch (err) {
    console.error(err)
  }
}
const loadDataPreguntas = (slug = '') => async (dispatch) => {
  //  trae todas la configuración del webinar

  try {
    const { data } = await axios.post(`https://nhy77jjich.execute-api.us-east-1.amazonaws.com/prod/getallquestions/${slug}`, { headers: { 'Content-Type': 'application/json' } })
    if (data.error) {
      console.error(data.error)
    }
    console.log(data)
    dispatch({
      type: LOAD_PREGUNTAS,
      payload: data
    })
  } catch (err) {
    console.error(err)
  }

  setInterval(async () => {
    try {
      const { data } = await axios.post(`https://nhy77jjich.execute-api.us-east-1.amazonaws.com/prod/getallquestions/${slug}`, { headers: { 'Content-Type': 'application/json' } })
      if (data.error) {
        console.error(data.error)
      }
      dispatch({
        type: LOAD_PREGUNTAS,
        payload: data
      })
    } catch (err) {
      console.error(err)
    }
  }, 10000)
}
const registry = (body) => async (dispatch) => {
  //  trae todas la configuración del webinar

  try {
    const { data } = await axios.post('https://d70255btwa.execute-api.us-east-1.amazonaws.com/prod/registro', body, { headers: { 'Content-Type': 'application/json' } })
    if (data.error) {
      console.error(data.error)
    }
    dispatch({
      type: SEND_REGISTRY,
      payload: data
    })
  } catch (err) {
    console.error(err)
  }
}
const registryQuestion = (body) => async (dispatch) => {
  //  trae todas la configuración del webinar

  try {
    const { data } = await axios.post('https://nhy77jjich.execute-api.us-east-1.amazonaws.com/prod/makequestion', body, { headers: { 'Content-Type': 'application/json' } })
    if (data.error) {
      console.error(data.error)
    }
    dispatch({
      type: SEND_QUESTION,
      payload: data
    })
  } catch (err) {
    console.error(err)
  }
}
const loadregistry = (id) => async (dispatch) => {
  //  trae todas la configuración del webinar

  try {
    const { data } = await axios.get(`https://d70255btwa.execute-api.us-east-1.amazonaws.com/prod/registro/${id}`, { headers: { 'Content-Type': 'application/json' } })
    if (data.error) {
      console.error(data.error)
    }
    // console.log('data registry -->', data)
    dispatch({
      type: LOAD_REGISTRY,
      payload: data
    })
  } catch (err) {
    console.error(err)
  }
}
export {
  loadDataWebinar,
  loadDataRegistros,
  registry,
  loadregistry,
  registryQuestion,
  loadDataPreguntas
}
